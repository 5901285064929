import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { OrderStatusAutocomplete } from "components/OrderStatusAutocomplete/OrderStatusAutocomplete"
import { ZahlartForm } from "components/ZahlartForm/ZahlartForm"
import { Dayjs } from "dayjs"
import {
  Address,
  OrderDetailsMore,
  OrderItemInput,
  OrderItemsCancelInput,
  Printer,
  Timeline,
} from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { CustomerEditPageAddress } from "pages/CustomerEditPage/CustomerEditPageAddress"
import { fetchOrderCancel } from "queries/fetchOrderCancel"
import { fetchOrderChange } from "queries/fetchOrderChange"
import { fetchPrinters } from "queries/fetchPrinters"
import { orderByID } from "queries/orderById"
import { orderItemsCancel } from "queries/orderItemsCancel"
import { orderTimeline } from "queries/orderTimeline"
import { printDeliveryNote } from "queries/printDeliveryNote"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CancelOrderDialog } from "./CancelOrderDialog"
import { OrderArticles } from "./OrderArticles"
import { OrderPackageStatus } from "./OrderPackageStatus"
import { OrderTimeline } from "./OrderTimeline"

const selectedPrinterEditOrderPageKey = "selectedPrinterEditOrderPage"

export const OrderDetailsPage = (): JSX.Element => {
  const { orderID } = useParams()
  const [order, setOrder] = useState<OrderDetailsMore>()
  const [orderBackup, setOrderBackup] = useState<OrderDetailsMore>()
  const [openAddress, setOpenAdress] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [orderChanged, setOrderChanged] = useState(false)
  const [orderItemsChanged, setOrderItemsChanged] = useState(false)
  const [timeline, setTimeline] = useState<Timeline[]>([])
  const [lieferadresse, setLieferadresse] = useState<Address | null>(null)
  const [selectedPrinter, setSelectedPrinter] = useState<Printer | null>(null)
  const [printers, setPrinters] = useState<Printer[]>([])
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const navigate = useNavigate()

  const handleOrderItemsChanged = () => {
    setOrderChanged(true)
    setOrderItemsChanged(true)
  }

  useEffect(() => {
    if (orderID !== undefined && orderID !== null && orderID !== "") {
      setIsLoadingData(true)
      // reset order
      setOrder(undefined)
      setOrderBackup(undefined)
      setOrderChanged(false)
      setOrderItemsChanged(false)
      setTimeline([])
      setLieferadresse(null)
      orderByID(parseInt(orderID))
        .then((result) => {
          if (result && result.orderByID) {
            setOrder(result.orderByID)
            setOrderBackup(result.orderByID)
          }
        })
        .finally(() => {
          setIsLoadingData(false)
        })

      orderTimeline(parseInt(orderID)).then((result) => {
        if (result) {
          setTimeline(result.orderTimeline)
        }
      })
    }
  }, [orderID])

  useEffect(() => {
    // load printer from server
    fetchPrinters().then((data) => {
      if (data?.printers) {
        setPrinters(data.printers)
        const sp = localStorage.getItem(selectedPrinterEditOrderPageKey)
        if (sp) {
          const printer = data.printers.find(
            (printer) => printer.printerID === Number(sp)
          )
          if (printer) {
            setSelectedPrinter(printer)
          }
        }
      }
    })
  }, [])

  const reloadData = () => {
    if (orderID !== undefined && orderID !== null && orderID !== "") {
      setIsLoadingData(true)
      // reset order
      setOrder(undefined)
      setOrderBackup(undefined)
      setOrderChanged(false)
      setOrderItemsChanged(false)
      setTimeline([])
      setLieferadresse(null)
      orderByID(parseInt(orderID))
        .then((result) => {
          if (result && result.orderByID) {
            setOrder(result.orderByID)
            setOrderBackup(result.orderByID)
          }
        })
        .finally(() => {
          setIsLoadingData(false)
        })

      orderTimeline(parseInt(orderID)).then((result) => {
        if (result) {
          setTimeline(result.orderTimeline)
        }
      })
    }
  }

  const changeAddress = () => {
    if (lieferadresse?.id === order?.deliveryAdr.id) {
      closeAddress()
      return
    }
    if (order && lieferadresse)
      setOrder({ ...order, deliveryAdr: lieferadresse })
    setOrderChanged(true)
    closeAddress()
  }

  const closeAddress = () => {
    setOpenAdress(false)
    setLieferadresse(null)
  }

  const handleOrderStatus = (newStatus: number) => {
    if (order) {
      setOrder({ ...order, status: newStatus })
      const articles: OrderItemInput[] = []
      if (orderItemsChanged) {
        for (const article of order.orderItems) {
          if (article.splitstatus) {
            // filter out split articles WILL NOT BE changeable
            continue
          }
          articles.push({
            ewId: article.ewID,
            bdb: article.artnum,
            stueckpreis: article.stueckpreis,
            anzahl: article.anzahl,
          })
        }
      }
      fetchOrderChange(
        order.frachtpausch,
        order.custnote,
        order.deliveryAdr.id,
        order.notiz,
        order.orderID.toString(),
        order.rabatt,
        order.secretnote,
        newStatus,
        order.zieldat,
        articles
      )
        .then((data) => {
          if (data?.orderChange) {
            enqueueSnackbar("Änderungen gespeichert", {
              variant: "success",
            })
          }
        })
        .finally(() => {
          orderByID(order.orderID)
            .then((result) => {
              if (result && result.orderByID) {
                setOrder(result.orderByID)
                setOrderBackup(result.orderByID)
                orderTimeline(order.orderID).then((result) => {
                  if (result) {
                    setTimeline(result.orderTimeline)
                  }
                })
              }
            })
            .finally(() => {
              setOrderChanged(false)
              setIsLoadingData(false)
            })
        })
    }
  }

  const resetOrder = () => {
    setOrder(orderBackup)
    setOrderChanged(false)
  }

  const saveOrder = () => {
    if (order) {
      const articles: OrderItemInput[] = []
      if (orderItemsChanged) {
        for (const article of order.orderItems) {
          if (article.splitstatus) {
            // filter out split articles WILL NOT BE changeable
            continue
          }
          articles.push({
            ewId: article.ewID,
            bdb: article.artnum,
            stueckpreis: article.stueckpreis,
            anzahl: article.anzahl,
          })
        }
      }
      fetchOrderChange(
        order.frachtpausch,
        order.custnote,
        order.deliveryAdr.id,
        order.notiz,
        order.orderID.toString(),
        order.rabatt,
        order.secretnote,
        order.status,
        order.zieldat,
        articles
      )
        .then((data) => {
          if (data?.orderChange) {
            enqueueSnackbar("Änderungen gespeichert", {
              variant: "success",
            })
          }
        })
        .finally(() => {
          orderByID(order.orderID)
            .then((result) => {
              if (result && result.orderByID) {
                setOrder(result.orderByID)
                setOrderBackup(result.orderByID)
                orderTimeline(order.orderID).then((result) => {
                  if (result) {
                    setTimeline(result.orderTimeline)
                  }
                })
              }
            })
            .finally(() => {
              setOrderChanged(false)
              setIsLoadingData(false)
            })
        })
    }
  }

  const handlePrintDelieveryNote = () => {
    if (selectedPrinter && orderID) {
      const printerIpPort =
        selectedPrinter.printerIP + ":" + selectedPrinter.printerPort
      printDeliveryNote(parseInt(orderID), printerIpPort).then((data) => {
        if (data?.printDeliveryNote) {
          enqueueSnackbar("Lieferschein wird gedruckt", {
            variant: "info",
          })
        }
      })
    }
  }

  //TODO: reuse when clarified
  //const handleExportBill = async (_: any) => {
  //if (order) {
  //exportBill(order.orderID)
  //.then((data) => {
  //if (data?.exportBill) {
  //const blb = base64ToBlob(data.exportBill, "application/pdf")
  //const now = dayjs().format("DD.MM.YYYY_HH.mm")
  //downloadBlob(blb, "R" + order.orderID + "_" + now + ".pdf")
  //}
  //})
  //.finally(() => {
  //// do nothing
  //})
  //}
  //}

  const handleCancel = (value: OrderItemsCancelInput) => {
    if (
      order?.orderID !== undefined &&
      order.orderID !== null &&
      order.orderID !== 0
    ) {
      if (value.orderItems.length > 0) {
        // partial cancel
        const input: OrderItemsCancelInput = {
          ...value,
          orderID: order.orderID.toString(),
        }
        orderItemsCancel({ in: input })
          .then((data) => {
            if (data?.orderItemsCancel === true) {
              enqueueSnackbar("Artikelpositionen storniert", {
                variant: "success",
              })
            }
          })
          .finally(() => {
            setOpenCancelDialog(false)
            reloadData()
          })
      } else {
        // only set the status to 15 IF bezahlt === 0
        if (order.bezahlt === 0) {
          handleOrderStatus(15)
          return
        }
        // cancel whole order
        fetchOrderCancel(order.orderID.toString(), value.action)
          .then((data) => {
            if (data?.orderCancel) {
              enqueueSnackbar("Auftrag storniert", {
                variant: "success",
              })
            }
          })
          .finally(() => {
            setOpenCancelDialog(false)
            reloadData()
          })
      }
    }
  }

  const adressEditble = (): boolean => {
    if (order && order.packages.length > 0) return false
    return true
  }

  return (
    <Container maxWidth={false}>
      {isLoadingData && (
        <Box sx={{ height: "185px" }}>
          <Typography>Auftrag wird geladen...</Typography>
        </Box>
      )}
      {order && (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <Grid container spacing={1} sx={{ mb: 3 }}>
                        <Grid item xs={12}>
                          <Typography variant="h6" component="div" gutterBottom>
                            Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            onClick={() =>
                              navigate("/editCustomer/" + order.kundnum)
                            }
                            variant="contained"
                            fullWidth
                          >
                            Kundennummer: {order.kundnum}
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Bestellnr."
                            value={order.orderID}
                            disabled
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Urorder"
                            value={order.urorder}
                            disabled
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <OrderStatusAutocomplete
                            status={order.status}
                            handleOrderStatusChange={handleOrderStatus}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Runde"
                            value={order.roundName}
                            disabled
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="SH"
                            value={order.sh}
                            disabled
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Grid container spacing={1} sx={{ mb: 3 }}>
                        <Grid item xs={12}>
                          <Typography variant="h6" component="div" gutterBottom>
                            Zahlungen
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Rechnungssumme"
                            value={order.rechsum.toFixed(2)}
                            disabled
                            fullWidth
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  €
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Frachtpauschale"
                            value={order.frachtpausch}
                            fullWidth
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  €
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              if (parseFloat(e.target.value) >= 0) {
                                setOrder({
                                  ...order,
                                  frachtpausch: parseFloat(e.target.value),
                                })
                                setOrderChanged(true)
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Rabatt"
                            value={order.rabatt}
                            fullWidth
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              if (parseFloat(e.target.value) >= 0) {
                                setOrder({
                                  ...order,
                                  rabatt: parseFloat(e.target.value),
                                })
                                setOrderChanged(true)
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Bezahlt"
                            value={order.bezahlt}
                            disabled
                            fullWidth
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  €
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              if (parseFloat(e.target.value) >= 0) {
                                setOrder({
                                  ...order,
                                  bezahlt: parseFloat(e.target.value),
                                })
                                setOrderChanged(true)
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ZahlartForm
                            disabled={true}
                            value={order.zahlart}
                            onChange={(e) => {
                              setOrder({
                                ...order,
                                zahlart: e.target.value,
                              })
                              setOrderChanged(true)
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            label="Zieldatum"
                            value={order.zieldat}
                            disabled
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DatePicker
                            disablePast
                            label="Zieldatum setzen"
                            sx={{ width: "100%", height: "100%" }}
                            onChange={(date: Dayjs | null) => {
                              if (date) {
                                setOrder({
                                  ...order,
                                  zieldat:
                                    "festes Datum (" +
                                    date.format("DD.MM.YYYY") +
                                    ")",
                                })
                                setOrderChanged(true)
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              setOrder({ ...order, zieldat: "Standard ()" })
                              setOrderChanged(true)
                            }}
                          >
                            Zieldatum zurücksetzen
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Grid container spacing={1} sx={{ mb: 3 }}>
                        <Grid item xs={12}>
                          <Typography variant="h6" component="div" gutterBottom>
                            Coupons
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Coupon Key"
                            disabled
                            value={order.couponKey}
                            fullWidth
                            onChange={(e) => {
                              setOrder({
                                ...order,
                                couponKey: e.target.value,
                              })
                              setOrderChanged(true)
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Coupon Wert"
                            disabled
                            value={order.couponValue}
                            fullWidth
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  €
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              if (parseFloat(e.target.value) >= 0) {
                                setOrder({
                                  ...order,
                                  couponValue: parseFloat(e.target.value),
                                })
                                setOrderChanged(true)
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} sx={{ mb: 3 }}>
                        <Grid item xs={12}>
                          <Typography variant="h6" component="div" gutterBottom>
                            Rücküberweisung
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Betrag"
                            value={order.rue}
                            disabled
                            fullWidth
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  €
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              if (parseFloat(e.target.value) >= 0) {
                                setOrder({
                                  ...order,
                                  rue: parseFloat(e.target.value),
                                })
                                setOrderChanged(true)
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Text"
                            value={order.rueText}
                            disabled
                            fullWidth
                            onChange={(e) => {
                              setOrder({
                                ...order,
                                rueText: e.target.value,
                              })
                              setOrderChanged(true)
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      {order.deliveryAdr && (
                        <Grid container spacing={1} sx={{ mb: 1 }}>
                          <Grid item xs={12} md={6}>
                            <Typography
                              variant="h6"
                              component="div"
                              gutterBottom
                            >
                              Lieferadresse
                            </Typography>
                          </Grid>
                          {adressEditble() && (
                            <Grid item xs={12} md={6}>
                              <Button
                                fullWidth
                                variant="contained"
                                onClick={() => setOpenAdress(true)}
                              >
                                Ändern
                              </Button>
                            </Grid>
                          )}
                          {!adressEditble() && (
                            <Grid item xs={12} md={6}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100%",
                                  flex: 1,
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  component="div"
                                  color="error"
                                  gutterBottom
                                >
                                  Adresse nicht änderbar
                                </Typography>
                              </Box>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              Firma: {order.deliveryAdr.company}
                            </Typography>
                            <Typography variant="body2">
                              {order.deliveryAdr.anrede}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ wordWrap: "break-word" }}
                            >
                              {order.deliveryAdr.fullName}
                            </Typography>
                            <Typography variant="body2">
                              {order.deliveryAdr.street}
                            </Typography>
                            <Typography variant="body2">
                              {order.deliveryAdr.zipCode}{" "}
                              {order.deliveryAdr.city}
                            </Typography>
                            <Typography variant="subtitle2">
                              {order.deliveryAdr.country}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}

                      <Grid container spacing={1} sx={{ mb: 3 }}>
                        <Grid item xs={12}>
                          <Typography variant="h6" component="div" gutterBottom>
                            Notizen
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Notiz"
                            value={order.notiz}
                            fullWidth
                            multiline
                            minRows={4}
                            onChange={(e) => {
                              setOrder({
                                ...order,
                                notiz: e.target.value,
                              })
                              setOrderChanged(true)
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="custnote"
                            value={order.custnote}
                            fullWidth
                            multiline
                            onChange={(e) => {
                              setOrder({
                                ...order,
                                custnote: e.target.value,
                              })
                              setOrderChanged(true)
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="secretnote"
                            value={order.secretnote}
                            fullWidth
                            multiline
                            onChange={(e) => {
                              setOrder({
                                ...order,
                                secretnote: e.target.value,
                              })
                              setOrderChanged(true)
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={resetOrder}
                    disabled={orderChanged === false}
                    color="error"
                  >
                    Änderungen zurücksetzen
                  </Button>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={!orderChanged}
                    onClick={saveOrder}
                  >
                    Änderungen speichern
                  </Button>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="printer-select-label">Drucker</InputLabel>
                    <Select
                      labelId="printer-select-label"
                      id="printer-select"
                      value={selectedPrinter?.printerID || ""}
                      label="Drucker"
                      onChange={(event) => {
                        const printerID = event.target.value as number
                        const printer = printers.find(
                          (printer) => printer.printerID === printerID
                        )
                        setSelectedPrinter(printer || null)
                        localStorage.setItem(
                          selectedPrinterEditOrderPageKey,
                          printerID.toString()
                        )
                      }}
                    >
                      {printers.map((printer) => (
                        <MenuItem
                          key={printer.printerID}
                          value={printer.printerID}
                        >
                          {printer.printerID} - {printer.printerIP}:
                          {printer.printerPort}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    variant="contained"
                    onClick={handlePrintDelieveryNote}
                    fullWidth
                    disabled={selectedPrinter === null}
                  >
                    Lieferschein drucken
                  </Button>
                </Grid>
                {/*
            <Grid item xs={12} md={3}>
              <Button variant="contained" fullWidth onClick={handleExportBill}>
                Rechnung (PDF)
              </Button>
            </Grid>
            */}
                <Grid item xs={12} md={4}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      if (order.status === 15 || order.status === 17) {
                        enqueueSnackbar("Auftrag bereits storniert", {
                          variant: "info",
                        })
                        return
                      }
                      setOpenCancelDialog(true)
                    }}
                  >
                    Auftrag stornieren
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <OrderArticles
                    order={order}
                    setOrder={setOrder}
                    reloadData={reloadData}
                    setOrderItemsChanged={handleOrderItemsChanged}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OrderPackageStatus packages={order.packages} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <OrderTimeline
                timeline={timeline}
                setTimeline={setTimeline}
                orderId={order.orderID}
              />
            </Grid>
          </Grid>

          <Dialog
            open={openAddress}
            onClose={closeAddress}
            fullWidth
            maxWidth={false}
          >
            <DialogTitle>Lieferadresse auswählen</DialogTitle>
            <DialogContent>
              <CustomerEditPageAddress
                customerID={order.kundnum.toString()}
                setSelectedAddress={setLieferadresse}
                open={true}
              />
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={closeAddress}
              >
                Abbrechen
              </Button>
              <Button fullWidth variant="contained" onClick={changeAddress}>
                Speichern
              </Button>
            </DialogActions>
          </Dialog>

          <CancelOrderDialog
            open={openCancelDialog}
            close={() => setOpenCancelDialog(false)}
            handleCancel={handleCancel}
            orderItems={order.orderItems}
            order={order}
          />
        </Box>
      )}
    </Container>
  )
}
