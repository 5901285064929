import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  FormControlLabel,
  FormGroup,
  Grid,
  Slide,
  Typography,
} from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { useAppSelector } from "app/hooks"
import { dataGridDEde } from "constants/dataGridLocale"
import { ADMIN, BASE_DATA, hasPrivilege } from "constants/privileges"
import dayjs from "dayjs"
import {
  ArticleChangelogItem,
  ArticleDetail,
  ArticleInventoryReport,
  ArticleItem,
  ArticlePriceHistory,
  ArticleSupplier,
  PlantType,
} from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { articleDetailsSave } from "queries/articleDetailSave"
import { articleDetailsMulti } from "queries/articleDetailsMulti"
import { fetchArticleVariants } from "queries/articleSearch"
import { suppliersGet } from "queries/fetchSuppliers"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ArticleEditPageBaseData from "./ArticleEditPageBaseData"
import ArticleEditPagePriceHistory from "./ArticleEditPagePriceHistory"
import ArticleEditPageSuppliers from "./ArticleEditPageSuppliers"

const articleChangeLogCols: GridColDef<ArticleChangelogItem>[] = [
  {
    field: "changeDate",
    type: "date",
    valueFormatter: (params) => {
      return params.value.format("DD.MM.YYYY HH:mm")
    },
    valueGetter: (params) => {
      return dayjs(params.row.changeDate)
    },
    headerName: "Änderungsdatum",
    width: 175,
  },
  {
    field: "userName",
    headerName: "Nutzer",
    width: 200,
  },

  {
    field: "action",
    headerName: "Aktion",
    width: 600,
  },
]

const inventoryReportCols: GridColDef<ArticleInventoryReport>[] = [
  {
    field: "supplier",
    headerName: "Lieferant",
    width: 240,
  },
  {
    field: "price",
    headerName: "Preis (€)",
    width: 120,
  },
  {
    field: "sticky",
    headerName: "Sticky",
    width: 120,
    renderCell: (params) => {
      return params.row.sticky ? (
        <CheckCircleIcon color="primary" />
      ) : (
        <CancelIcon color="error" />
      )
    },
  },
  {
    field: "quality",
    headerName: "Qualität",
    width: 240,
  },
  {
    field: "quantity",
    headerName: "Anzahl",
    width: 120,
  },
  {
    field: "saugtxt",
    headerName: "Saugtxt",
    width: 300,
  },
  {
    field: "createdAt",
    headerName: "Erstellt am",
    type: "date",
    valueGetter: (params) => {
      if (params.row.createdAt === "") {
        return dayjs("1970-01-01")
      }
      return dayjs(params.row.createdAt)
    },
    valueFormatter: (params) => {
      return dayjs(params.value).format("DD.MM.YYYY")
    },
    width: 125,
  },
]

// simplified data object containing only the values we need
export interface supplier {
  id: number
  name: string
  G24: number
}

export const TransitionSlideIn = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const artVariantCols: GridColDef<ArticleItem>[] = [
  {
    field: "bdb",
    headerName: "BDB",
    width: 120,
  },
  {
    field: "pflNR",
    headerName: "PflNR",
    width: 120,
  },
  {
    field: "botname",
    headerName: "Botanischer Name",
    width: 350,
  },
  {
    field: "gername",
    headerName: "Deutscher Name",
    width: 300,
  },
  {
    field: "size",
    headerName: "Größe",
    width: 120,
    valueGetter: (params) => {
      if (params.row.sizeFrom && params.row.sizeTo) {
        return params.row.sizeFrom + "-" + params.row.sizeTo
      }
    },
  },
  {
    field: "price",
    headerName: "Preis",
    type: "number",
    width: 120,
  },
  {
    field: "reducedPrice",
    headerName: "Aktions Preis",
    type: "number",
    width: 120,
  },
]

const ArticleEditPage = (): JSX.Element => {
  const { bdb } = useParams()
  const { isLoading } = useAppSelector((state) => state.loadingState)
  const [variantDialogisOpen, setVariantDialogisOpen] = useState(false)
  const [articleVariants, setArticleVariants] = useState<ArticleItem[]>([])
  const [displayInactiveVariants, setDisplayInactiveVariants] = useState(false)
  const [isLoadRequired, setIsLoadRequired] = useState(true)
  const [prioOneSupplierChanged, setPrioOneSupplierChanged] = useState(false)
  const [articleDetail, setArticleDetail] = useState<ArticleDetail | null>(null)
  const [origArticleDetail, setOrigArticleDetail] =
    useState<ArticleDetail | null>(null)
  const [allPlantTypes, setAllPlantTypes] = useState<PlantType[]>([])
  const [articleSuppliers, setArticleSuppliers] = useState<ArticleSupplier[]>(
    []
  )
  const [articlePriceHistory, setArticlePriceHistory] = useState<
    ArticlePriceHistory[]
  >([])
  const [articleInventoryReports, setArticleInventoryReports] = useState<
    ArticleInventoryReport[]
  >([])
  const [articleChangelog, setArticleChangelog] = useState<
    ArticleChangelogItem[]
  >([])
  // this is the full set of all suppliers in the system
  const [allSuppliers, setAllSuppliers] = useState<supplier[]>([])
  // get user from store
  const user = useAppSelector((state) => state.userState.user)
  const hasAdminPermission = hasPrivilege(user.privileges, ADMIN)
  let hasEditPermission = hasPrivilege(user.privileges, BASE_DATA)
  if (hasAdminPermission) {
    hasEditPermission = true
  }

  useEffect(() => {
    suppliersGet().then((res) => {
      if (res) {
        const importedSuppliers: supplier[] = res.suppliers
          .filter((s) => s.active === true)
          .map((s) => {
            return {
              id: s.id,
              name: s.name,
              G24: Number(s.G24),
            }
          })
          .sort((a, b) => a.name.localeCompare(b.name))
        setAllSuppliers(importedSuppliers)
      } else {
        enqueueSnackbar("Lieferanten konnten nicht geladen werden", {
          variant: "error",
        })
      }
    })
  }, [])

  const handleArticleDetailsSaved = async (newArticleDetail: ArticleDetail) => {
    if (
      JSON.stringify(origArticleDetail) === JSON.stringify(newArticleDetail)
    ) {
      return // do not save as there is no change
    }
    // check min date for reduced price
    const reducedPriceUntil = dayjs(newArticleDetail.reducedPriceUntil)
    if (reducedPriceUntil.year() < 1970) {
      newArticleDetail.reducedPriceUntil =
        dayjs("1970-01-01").format("YYYY-MM-DD")
    }
    const r = await articleDetailsSave({
      in: {
        bdb: newArticleDetail.bdb,
        pfNr: newArticleDetail.pflNR,
        botname: newArticleDetail.botname,
        gername: newArticleDetail.gername,
        price: newArticleDetail.price,
        reducedPrice: newArticleDetail.reducedPrice,
        reducedPriceUntil: dayjs(newArticleDetail.reducedPriceUntil).format(
          "YYYY-MM-DD"
        ),
        location: newArticleDetail.location,
        stock: +newArticleDetail.stock,
        localStock: +newArticleDetail.localStock,
        h24: newArticleDetail.h24,
        sizeFrom: newArticleDetail.sizeFrom,
        sizeTo: newArticleDetail.sizeTo,
        trunkSizeFrom: newArticleDetail.trunkSizeFrom,
        trunkSizeTo: newArticleDetail.trunkSizeTo,
        trunkHeight: newArticleDetail.trunkHeight,
        customerContainerSize: newArticleDetail.customerContainerSize,
        supplierContainerSize: newArticleDetail.supplierContainerSize,
        sorte: newArticleDetail.sorte,
        quali: newArticleDetail.quali,
        variant: newArticleDetail.variant,
        saleMinCount: newArticleDetail.saleMinCount,
        salePct: newArticleDetail.salePct,
        availableFrom: newArticleDetail.availableFrom,
        statusAfterSoldOut: newArticleDetail.statusAfterSoldOut,
        supplierAfterSoldOut: newArticleDetail.supplierAfterSoldOut,
        articleGroup: newArticleDetail.articleGroup,
      },
    })

    if (r?.articleDetailsSave === true) {
      enqueueSnackbar("Änderungen erfolgreich gespeichert", {
        variant: "success",
      })
    }

    const res = await articleDetailsMulti(newArticleDetail.bdb)
    if (res?.articleDetails) {
      setArticleDetail(res.articleDetails)
      setOrigArticleDetail(res.articleDetails)
    }
    if (res?.articleSupplier) {
      setArticleSuppliers(res.articleSupplier)
    }
    if (res?.articlePriceHistory) {
      setArticlePriceHistory(res.articlePriceHistory)
    }
    if (res?.articleInventoryReports) {
      setArticleInventoryReports(res.articleInventoryReports)
    }
    if (res?.articleChangelog) {
      setArticleChangelog(res.articleChangelog)
    }
  }

  useEffect(() => {
    if (!bdb || !isLoadRequired) {
      return
    }

    articleDetailsMulti(bdb)
      .then((res) => {
        if (res?.articleDetails) {
          setArticleDetail(res.articleDetails)
          setOrigArticleDetail(res.articleDetails)
        }
        if (res?.articleSupplier) {
          setArticleSuppliers(res.articleSupplier)
        }
        if (res?.articlePriceHistory) {
          setArticlePriceHistory(res.articlePriceHistory)
        }
        if (res?.articleInventoryReports) {
          setArticleInventoryReports(res.articleInventoryReports)
        }
        if (res?.articleChangelog) {
          setArticleChangelog(res.articleChangelog)
        }
        if (res?.plantTypes) {
          setAllPlantTypes(res.plantTypes)
        }
      })
      .finally(() => {
        setIsLoadRequired(false)
      })
  }, [bdb, isLoadRequired])

  // if the prio one supplier is changed, we must check to see if base values change also
  if (prioOneSupplierChanged && articleSuppliers) {
    if (articleSuppliers.length > 0) {
      const prioOneSupplier = allSuppliers.find(
        (s) => s.id === articleSuppliers[0].supplierID
      )
      if (
        prioOneSupplier &&
        articleDetail &&
        prioOneSupplier.G24 !== Number(articleDetail.h24)
      ) {
        handleArticleDetailsSaved({
          ...articleDetail,
          h24: prioOneSupplier.G24.toString(),
        })
      }
    } else if (articleDetail) {
      handleArticleDetailsSaved({
        ...articleDetail,
        h24: "0",
      })
    }
    setPrioOneSupplierChanged(false)
  }

  const handleShowVariants = () => {
    // load variants
    if (articleDetail) {
      fetchArticleVariants(articleDetail?.pflNR, displayInactiveVariants).then(
        (res) => {
          if (res?.articleVariants) {
            setArticleVariants(res.articleVariants)
            setVariantDialogisOpen(true)
          }
        }
      )
    }
  }

  return (
    <Container maxWidth="xl">
      {articleDetail && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleShowVariants}
            >
              Varianten anzeigen
            </Button>
            <Dialog
              fullScreen
              open={variantDialogisOpen}
              onClose={() => setVariantDialogisOpen(false)}
              TransitionComponent={TransitionSlideIn}
            >
              <Container maxWidth={false}>
                <Button
                  variant="contained"
                  onClick={() => setVariantDialogisOpen(false)}
                >
                  Zurück
                </Button>
                <Typography variant="h6" component="div" gutterBottom>
                  {articleDetail.botname} PflNR: {articleDetail.pflNR}
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={displayInactiveVariants}
                        onChange={(_, checked) => {
                          setDisplayInactiveVariants(checked)
                          if (articleDetail) {
                            fetchArticleVariants(
                              articleDetail?.pflNR,
                              checked
                            ).then((res) => {
                              if (res?.articleVariants) {
                                setArticleVariants(res.articleVariants)
                              }
                            })
                          }
                        }}
                      />
                    }
                    label="Inaktive Varianten anzeigen"
                  />
                </FormGroup>
                <Box sx={{ height: "85vh", width: "100%" }}>
                  <DataGridPro
                    rows={articleVariants}
                    onRowClick={(params) => {
                      window.open(`/#/editArticle/${params.id}`, "_blank")
                    }}
                    getRowId={(row) => row.bdb}
                    columns={artVariantCols}
                    localeText={dataGridDEde}
                    loading={isLoading}
                  />
                </Box>
              </Container>
            </Dialog>
          </Grid>
          <Grid item xs={12}>
            <ArticleEditPageBaseData
              articleDetail={articleDetail}
              articleSuppliers={articleSuppliers}
              setArticleDetail={setArticleDetail}
              handleArticleDetailsSaved={handleArticleDetailsSaved}
              plantTypes={allPlantTypes}
              hasEditPermission={hasEditPermission}
            />
          </Grid>
          <Grid item xs={12}>
            <ArticleEditPageSuppliers
              articleBdb={articleDetail.bdb}
              articleSuppliers={articleSuppliers}
              allSuppliers={allSuppliers}
              setArticleSuppliers={setArticleSuppliers}
              setIsLoadRequired={setIsLoadRequired}
              setPrioOneSupplierChanged={setPrioOneSupplierChanged}
              hasEditPermission={hasEditPermission}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="div" gutterBottom>
              Log - Bestandsmeldungen
            </Typography>
            <Box sx={{ height: "40vh", width: "100%" }}>
              <DataGridPro
                rows={articleInventoryReports}
                columns={inventoryReportCols}
                localeText={dataGridDEde}
                loading={isLoading}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="div" gutterBottom>
              Log - Artikeländerungen
            </Typography>
            <Box sx={{ height: "40vh", width: "100%", marginBottom: 5 }}>
              <DataGridPro
                rows={articleChangelog}
                columns={articleChangeLogCols}
                localeText={dataGridDEde}
                loading={isLoading}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <ArticleEditPagePriceHistory priceHistory={articlePriceHistory} />
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ArticleEditPage
